.book-container {
    text-align: left;
    padding: 60px 10%;
    background: linear-gradient(to bottom, #f8f9fa, #e9ecef);
  }
  #livres{
    scroll-margin-top: 100px; /* Adjust this based on your header height */
  }
  .section-title {
    font-size: 42px; /* Increase size */
    font-weight: 900; /* Make it bolder */
    color: #222; /* Keep the same color */
    margin-bottom: 20px;
    text-align: left;
  }
  
  .book-intro {
    font-weight: 500; /* Make it bolder */
    font-size: 20px;
    color: rgb(0, 0, 0);
    max-width: 1200px;
    font-weight: 500;
    margin-bottom: 40px;
    margin-left: 0; /* Align intro with title */
  }
  
  .book-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
    max-width: 1000px;
    
    text-align: left;
  }
  
  .book-left {
    display: flex;
    align-items: flex-start;
  }
  
  .book-image {
    width: 280px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
    margin-top: 30px; /* Moves the book image lower */
  }
  
  .book-details {
    max-width: 600px;
    margin-left: 10px;
  }
  
  .book-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #111;
  }
  
  .book-description {
    font-size: 1rem;
    color: rgb(0, 0, 0);
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  /* Buy Buttons inside the box */
  .buy-buttons {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    margin-top: 20px;
  }
  
  .buy-btn {
    display: inline-block;
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    padding: 15px 35px;
    border-radius: 8px;
    font-weight: bold;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    transition: background 0.3s ease-in-out, transform 0.2s;
  }
  
  .buy-btn:hover {
    transform: scale(1.07);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .amazon {
    background: #ff9900;
  }
  
  .indigo {
    background: #0033a0;
  }
  