.media-section {
  width: 80%;
  margin: auto;
  padding: 50px 0;
  text-align: center;
}
#medias{
  scroll-margin-top: 100px; /* Adjust this based on your header height */
}

/* Section Titles */
.media-title {
  font-size: 42px; /* Increase size */
  font-weight: 900; /* Make it bolder */
  color: rgb(1, 1, 1); /* Keep the same color */
  margin-bottom: 20px;
  text-align: center;
}

.media-subtitle {
  font-size: 2rem;
  margin: 50px 0 20px;
  color: #333;
  font-weight: 900; /* Make it bolder */
    font-size: 42px;
}

/* Grid for Documentary & Article */
.media-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.media-card {
  background: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: left;
  padding: 20px;
  width: 450px;
  transition: transform 0.2s ease-in-out;
}

.media-card:hover {
  transform: translateY(-5px);
}

.media-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.media-content {
  padding: 15px 0;
}

.media-card h2 {
  font-size: 1.4rem;
  color: #333;
  
}

.media-card p {
  font-size: 1rem;
  color: rgb(0, 0, 0);
  font-weight: 500; /* Make it bolder */
    font-size: 20px;
}

.media-link {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 15px;
  background: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  font-weight: bold;
}

.media-link:hover {
  background: #0056b3;
}

/* TV Appearances Section */
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  justify-content: center;
}

.video-card {
  background: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  padding: 15px;
  transition: transform 0.2s ease-in-out;
}

.video-card:hover {
  transform: translateY(-5px);
}

.video-card iframe {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}

.video-card h3 {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #333;
}

.video-date {
  font-size: 0.9rem;
  color: #777;
}
