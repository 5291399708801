/* Contact Section */
.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 40px auto;
    gap: 40px;
    
}

/* Left Side */
.contact-info {
    flex: 1;
    max-width: 60%;
    text-align: left;
    
}
#contact{
    scroll-margin-top: 100px; /* Adjust this based on your header height */
  }

.contact-info h2 {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 15px;
    font-weight: 900; /* Make it bolder */
    
}

.contact-text {
    color: rgb(0, 0, 0);
    margin-bottom: 20px;
    line-height: 1.5;
    font-weight: 500; /* Make it bolder */
    font-size: 20px;
}

/* Contact Buttons Section */
.contact-buttons {
    display: flex;
    flex-direction: column; /* Stack buttons */
    align-items: flex-start; /* Align left */
    gap: 10px; /* Space between buttons */
}

/* General Button Styling */
.contact-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    width: 250px; /* Ensuring all buttons have the same width */
    border-radius: 8px;
    font-weight: bold;
    border: 2px solid transparent;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* Red Email Button */
.email-button {
    background-color: red;
    border-color: red;
}

/* Blue Facebook Button */
.facebook-button {
    background-color: #1877f2;
    border-color: #1877f2;
}

/* Orange "Aider la recherche" Button */
.research-button {
    background-color: #ff6600 !important;
    border-color: #ff6600 !important;
}

/* Button Hover Effects */
.contact-item:hover {
    opacity: 0.8;
}

/* Icon Styling */
.contact-icon {
    margin-right: 8px;
    font-size: 20px;
}

/* Contribution Section */
.contribution-section {
    margin-top: 30px;
}

.contribution-text {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin-bottom: 10px;
    font-weight: 500; /* Make it bolder */
    font-size: 20px;
}

/* Right Side: Image */
.contact-image {
    flex: 1;
    max-width: 45%;
    display: flex;
    justify-content: center;
}

/* Profile Photo */
.profile-photo {
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    object-fit: cover;
}

/* Responsive Design */
@media (max-width: 900px) {
    .contact-container {
        flex-direction: column;
        text-align: center;
    }

    .contact-info {
        max-width: 100%;
    }

    .contact-buttons {
        justify-content: center;
        align-items: center; /* Center buttons on smaller screens */
    }

    .contribution-section {
        text-align: center;
    }

    .contact-image {
        max-width: 100%;
        justify-content: center;
        margin-top: 20px;
    }

    .profile-photo {
        max-width: 300px;
    }
}
