.web-series {
    width: 99%; /* Adds spacing on both sides */
    max-width: 1400px; /* Ensures it doesn't get too wide on large screens */
    margin: 50px auto; /* Centers the section */
    text-align: center;
    padding: 40px 20px;
    background: linear-gradient(180deg, #d8dfec, #c3cfe2); /* Subtle gradient */
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}
 #serie-web{
    scroll-margin-top: 120px; /* Adjust this based on your header height */
  }
  
  /* Title & Subtitle */
  .title {
    font-size: 42px; /* Increase size */
    font-weight: 900; /* Make it bolder */
    color: #222; /* Keep the same color */
    margin-bottom: 20px;
    text-align: center;
  }
  
  .subtitle {
    font-size: 18px;
    font-weight: bold;
    color: #444;
    margin-bottom: 20px;
  }
  
  .highlight {
    color: #007bff;
    font-weight: bold;
  }
  
  /* Language Toggle */
  .language-toggle {
    margin-bottom: 20px;
  }
  
  .language-toggle button {
    padding: 12px 26px;
    margin: 0 8px;
    border: none;
    border-radius: 25px;
    background: #d3d3d3;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
  }
  
  .language-toggle button.active {
    background: linear-gradient(135deg, #007bff, #00c6ff);
    color: white;
    box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.3);
  }
  
  .language-toggle button:hover {
    background: linear-gradient(135deg, #0056b3, #0099ff);
    color: white;
  }
  
  /* Video Container */
  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .video-container iframe {
    width: 75%;
    height: 55vh;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .episode-title {
    margin-top: 12px;
    font-size: 22px;
    font-weight: bold;
    color: #333;
  }
  
  /* Divider */
  .divider {
    width: 80%;
    height: 1px;
    background: #bbb;
    margin: 20px auto;
    opacity: 0.5;
  }
  
  /* Episode Grid */
  .episode-grid {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 25px;
  }
  
  .episode {
    padding: 14px 20px;
    background: #eee;
    cursor: pointer;
    border-radius: 25px;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    font-weight: bold;
  }
  
  .episode:hover {
    background: linear-gradient(135deg, #007bff, #00c6ff);
    color: white;
    box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.3);
  }
  
  .episode.selected {
    background: linear-gradient(135deg, #0056b3, #0099ff);
    color: white;
    box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.5);
  }
  