/* General Introduction Section */
.introduction-container {
    width: 99%; /* Reduce width to create space on both sides */
    max-width: 1400px; /* Ensures it doesn't get too wide on large screens */
    margin: 50px auto; /* Centers the section */
    padding: 40px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
#qui-suis-je {
    scroll-margin-top: 120px; /* Adjust this based on your header height */
  }

  /* Titles */
  .section-title {
    font-size: 40px;
    font-weight: bold;
    color: #222;
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Intro & Conclusion Wrappers */
  .intro-wrapper,
  .conclusion-wrapper {
    display: flex;
    border-radius: 10px;
    justify-content: space-between;
    gap: 50px; /* Increased spacing to push images further from the left */
    margin-bottom: 10px; /* Added more bottom spacing */
  }
  
  /* Add More Space Between Conclusion and Timeline */
  .conclusion-wrapper {
    margin-bottom: 30px; /* Creates space between conclusion and timeline */
  }
  .intro-text h1 {
    font-size: 42px; /* Increase size */
    font-weight: 900; /* Make it bolder */
    color: #222; /* Keep the same color */
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Text Styling */
  .intro-text,
  .conclusion-text {
    flex: 1;
    max-width: 850px;
    text-align: left;
  }
  
  p {
    font-size: 20px;
    line-height: 1.6;
    color: rgb(0, 0, 0);
    font-weight: 500; /* Make it bolder */
    font-size: 20px;

  }
  
  /* Intro Image - Bigger & Black & White */
  .intro-image img {
    width: 250px; /* Increased size */
    height: auto;
    max-width: 70%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    filter: grayscale(100%); /* Black & white effect */
    margin-right: 120px; /* Push further from the left */
    margin-bottom: 20px;
  }
  
  /* Conclusion Image - Bigger & More Spaced */
  .conclusion-image img {
    width: 400px; /* Increased size */
    height: auto;
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    margin-right: 75px; /* Push further from the left */
    margin-top: 20px;
    
  }
  
  /* Timeline Wrapper */
  .timeline-wrapper {
    width: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;
    padding: 20px 0;
    scroll-snap-type: x mandatory;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
  }
  .timeline-title {
    font-size: 36px; /* Slightly smaller */
    font-weight: 800; /* Bold but not as much as "Mon Histoire" */
    color: #222;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Timeline */
  .timeline {
    display: flex;
    gap: 20px;
    padding-bottom: 10px;
  }
  
  /* Timeline Box */
  .timeline-item {
    flex: 0 0 auto;
    min-width: 320px;
    max-width: 360px;
    background: white;
    color: #222;
    padding: 30px 25px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
    scroll-snap-align: center;
    position: relative;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
  }
  
  .timeline-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.12);
  }
  
  /* Timeline Year - No Frame */
  .timeline-year {
    font-size: 22px;
    font-weight: bold;
    color: #222;
    background: transparent;
    padding: 5px 0;
    display: block;
    margin-bottom: 12px;
  }
  
  /* Timeline Content */
  .timeline-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 180px;
  }
  
  /* Timeline Title */
  .timeline-content h2 {
    font-size: 22px;
    font-weight: 800;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
    color: #007bff;
  }
  
  /* Timeline Text */
  .timeline-content p {
    font-weight: 500; /* Make it bolder */
    font-size: 20px;
    line-height: 1.7;
    color: #444;
  }
  
  /* Icons (Optional) */
  .timeline-icon {
    font-size: 28px;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  /* Scrollbar Styling */
  .timeline-wrapper::-webkit-scrollbar {
    height: 6px;
  }
  
  .timeline-wrapper::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 3px;
  }
  
  .timeline-wrapper::-webkit-scrollbar-track {
    background: #ddd;
  }

  .conclusion-text h2 {
    font-size: 36px; /* Same as "Mon Parcours avec le Parkinson" */
    font-weight: 800; /* Bold but not as much as "Mon Histoire" */
    color: #222;
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Responsive Design */
  @media (max-width: 900px) {
    .intro-wrapper,
    .conclusion-wrapper {
      flex-direction: column;
      text-align: left;
      gap: 30px;
    }
  
    .timeline-item {
      min-width: 280px;
    }
  
    .intro-image img {
      width: 300px; /* Slightly smaller for mobile */
      margin-left: 0;
    }
  
    .conclusion-image img {
      width: 350px; /* Slightly smaller for mobile */
      margin-left: 0;
    }
}