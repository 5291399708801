/* General Header Styling */
.header {
    width: 100%;
    font-family: "Inter", sans-serif;
    background-color: #fff;
}
  
/* Navigation Bar */
.navbar {
    display: flex;
    align-items: center;
    padding: 20px 50px;
    background: rgba(255, 255, 255, 0.95);
    border-bottom: 2px solid #e0e0e0;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    backdrop-filter: blur(12px);
}
  
/* Logo on the Left */
.nav-left {
    flex-grow: 1;
}
  
.nav-left .logo {
    height: 60px;
    cursor: pointer;
}
  
/* Centered Navigation Links */
.nav-center {
    flex-grow: 2;
    display: flex;
    justify-content: center;
}
  
.nav-links {
    display: flex;
    gap: 40px;
    list-style: none;
    margin: 0;
    padding: 0;
}
  
.nav-links li {
    display: inline;
}
  
.nav-links a {
    text-decoration: none;
    color: #222;
    font-size: 18px;
    font-weight: 600;
    padding: 12px 18px;
    transition: all 0.3s ease-in-out;
    border-radius: 6px;
}
  
.nav-links a:hover {
    background-color: #f2f2f2;
    color: #004085;
}
  
/* Hero Section */
.hero-section {
    position: relative;
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Title Styling */
.hero-title {
    position: absolute;
    left: 5%;
    color: #4993be; /* Dark blue */
    font-family: "Inter", sans-serif;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    font-weight: bold;
    display: flex;
    flex-direction: column;
    font-size: 35px;

}



/* Hero Image */
.hero-image {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    display: block;
    border-radius: 0;
}

/* Responsive Design */
@media (max-width: 900px) {
    .navbar {
      flex-direction: column;
      padding: 15px;
    }
  
    .nav-links {
      flex-direction: column;
      gap: 15px;
      text-align: center;
    }
  
    .hero-image {
      height: 60vh;
    }

    .hero-title {
      left: 10%;
    }
}
